<template>
  <div class="container join">
    <div class="ui-border-line" style="height: 10px"></div>

    <template v-if="step === 1 && !this.joinData.isAutoSet">
      <div class="join-inputbox">
        <input type="text" v-model="joinData.userName" placeholder="이름(실명)" required>
      </div>

      <div class="ui-border-line"></div>
    </template>

    <template v-if="step >= 2">
      <div class="join-inputbox">
        <input
          ref="phone"
          type="text"
          v-model="phone"
          @input=phoneFormat 
          placeholder="휴대폰 번호"
          style="width: 30rem"
          pattern="[0-9]+"
          required
        >
        <button type="button" class="btn-send-sms" @click="() => sendSms()">{{!confirm.regNum ? '인증요청' : '재요청'}}</button>
      </div>

      <div class="ui-border-line"></div>

      <div class="join-inputbox" v-if="confirm.regNum">
        <input type="text" v-model="joinData.regNum" placeholder="인증번호 4자리" minlength="4" maxlength="6" required>
        <div class="join-errorbox" v-if="error.regNum" @click="reset('regNum')">
          <icon class="error-icon" src="img/join/register-error-ico.png" width="2.4rem" height="11rem" :cover="false" />
          올바른 인증번호가 아닙니다. 다시 시도해주세요.
        </div>
      </div>

      <div class="ui-border-line"></div>

      <p class="ui-mt-6">
        회원가입을 하면 헤이비글 서비스의 <router-link to="/etc/terms">서비스 약관</router-link>, <router-link to="/etc/privacy">개인정보 보호정책</router-link>, <router-link to="/etc/refund-policy">환불정책</router-link> 등에 동의하는 것으로 간주됩니다.
      </p>
    </template>

    <!-- <template v-if="step >= 3">
      <div class="join-inputbox">
        <input type="email" v-model="joinData.email" placeholder="이메일을 입력해주세요" required>
        <div class="join-errorbox" v-if="error.email" @click="reset('email')">
          <icon class="error-icon" src="img/join/register-error-ico.png" width="2.4rem" height="11rem" :cover="false" />
          이미 가입이 되어있는 이메일 주소입니다!
        </div>
      </div>

      <div class="ui-border-line"></div>

      <p class="ui-mt-2"><a href="javascript:void(0)" @click="() => $gotoWeb('http://pf.kakao.com/_yzxhwC')">회원가입에 문제가 있나요?</a></p>

      <p class="ui-mt-6">
        회원가입을 하면 헤이비글 서비스의 <router-link to="/etc/terms">서비스 약관</router-link>, <router-link to="/etc/privacy">개인정보 보호정책</router-link>, <router-link to="/etc/refund-policy">환불정책</router-link> 등에 동의하는 것으로 간주됩니다.
      </p>
    </template> -->

    <footer-box
      :submit-text="submitText"
      :submit-cb="() => nextStep()"
      :submit-disabled="submitDisabled"
    />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import { resultCheck } from '@/lib/globalLib.js'

export default {
  name: 'JoinInput',
  components: {
    Icon,
    FooterBox,
  },
  data() {
    return {
      step: 1,
      joinData: {
        sns: null,
        snsUid: null,
        category: null,
        userName: null,
        nickName: null,
        email: null,
        phone: null,
        regNum: null,
        imgUrl: null,
        platform: null,
        interests: null,
        eventDate: null,
        referrer: null,
        memo: null,
        isAutoSet: false,
      },
      error: {
        email: false,
        regNum: false,
      },
      confirm: {
        regNum: null,
      },
      phone: '',
    }
  },
  computed: {
    userData() {
      return this.$store?.state?.user?.userData
    },
    isLogin() {
      return !!this.userData?.useridx
    },
    submitText() {
      return this.step >= 2 ? '회원 가입하기' : '다음'
    },
    submitDisabled() {
      if (this.step === 1) {
        return false
      }

      if (this.step >= 2) {
        if (!this.joinData.phone) {
          return true
        }

        if (!this.joinData.regNum) {
          return true
        }

        if (!this.confirm.regNum) {
          return true
        }

        if (this.joinData.regNum !== this.confirm.regNum) {
          return true
        }

        if (this.error.regNum) {
          return true
        }

        return false
      }

      // if (this.step >= 3) {
      //   return [
      //     !this.joinData.email,
      //     this.error.email,
      //   ].some(check => check)
      // }

      return true
    },
  },
  beforeRouteEnter (to, from, next) {
    next(Vue => cb.call(Vue))

    function cb() {
      if (this.isLogin) {
        this.$router.push('/home')
        return
      }

      this.$store.commit('setGnbLeftBtnCb', () => {
        this.prevStep()
      })

      this.focusInput()

      try {
        if (sessionStorage.getItem('joinData')) {
          let joinData = JSON.parse(sessionStorage.getItem('joinData'))
          if( joinData.userName != null){
            this.step = 2
          }
          if(joinData.phone != null){
            this.phone = joinData.phone
            this.phoneFormat()
          }
          this.joinData = {
            ...this.joinData,
            ...joinData
          }
        }
      } catch (error) {
        //
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    sessionStorage.setItem('joinData', JSON.stringify(this.joinData))
    this.$store.commit('setGnbLeftBtnCb', false)
    next()
  },
  methods: {
    /**
     * @param { 'email' | 'regNum' | 'step' } dataName
     */
    reset(dataName = null) {
      switch (dataName) {
        case 'email':
          this.joinData.email = null
          this.error.email = false
          break
        case 'regNum':
          this.joinData.regNum = null
          this.error.regNum = false
          break
        case 'step':
          this.step = 1
          break
      }
    },

    sendSms() {
      if (!this.joinData.phone) {
        this.$refs.phone.focus()
        this.$toast('휴대폰번호를 입력해주세요.')
        return
      }

      const req = {
        method: 'post',
        url: `/user/sms/${this.joinData.phone}`,
        data: {
          platform: sessionStorage.getItem('platform'),
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = resultCheck(data.resultData)

          if (resultData.success) {
            let IS_DEV = false
            let arr = window.location.hostname.split('.')
            if (/dev|local/.test(arr[0])) IS_DEV = true

            this.confirm.regNum = data.response.regNum

            this.$toast('인증번호를 발송하였습니다.')

            // 개발모드일 때 자동입력
            if (IS_DEV) this.joinData.regNum = data.response.regNum
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },

    joinUser() {
      if (this.joinData.regNum !== this.confirm.regNum) {
        this.error.regNum = true
        return
      }

      const req = {
        method: 'post',
        url: `/user`,
        data: this.joinData,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = resultCheck(data.resultData)

          if (resultData.success) {
            log('가입성공', data.response.useridx)

            this.$store.dispatch('login', {
              sns: this.joinData.sns,
              snsUid: this.joinData.snsUid,
              platform: this.joinData.platform,
            })
              .catch((error) => {
                log.error(error)
                return {
                  success: false,
                  code: null,
                  msg: '서버 오류가 발생하였습니다.',
                }
              })
              .then(result => {
                if (result.success) {
                  // 20231127 바로 진행되는 견적 받기 프로세스 미진행
                  // if (this.joinData.category === 'client') {
                  //   this.$store.commit('setPostOffer', {
                  //     startOfferFlag: 'y',
                  //   })
                  //   // this.$router.push('/offer/post/1')
                  //   return
                  // }

                  this.$router.push('/home')

                  // 스텝 초기화
                  //this.reset('step')
                  return
                } else if (result.msg) {
                  this.$alert(result.msg)
                }
              })

          } else {
            switch (resultData.code) {
              case -20:
                this.error.regNum = true
                break
              case -21:
                // this.error.email = true
                this.$toast('이미 다른 SNS로 가입되어 있어요. 다른 SNS를 선택해주세요.')
                break
              default:
                this.$alert(resultData)
                break
            }
          }
        })
        .catch(log.error)
    },
    nextStep() {
      if (this.step === 1 && String(this.joinData.userName || '').length < 2) {
        this.$toast('정확한 이름을 입력해주세요.')
        return
      }

      if (++this.step > 2) {
        this.step = 2
        this.joinUser()
        return
      }

      this.$nextTick(() => {
        this.focusInput()
      })
    },
    prevStep() {
      if (--this.step < 1 || this.joinData.isAutoSet) {
        if(!this.joinData.isAutoSet){
          this.step = 1
        }
        this.$router.back()
      }
    },
    focusInput() {
      document.querySelectorAll('.join-inputbox input')?.[0]?.focus?.()
    },
    phoneFormat() {
      if(this.phone.length > 0){
        this.phone = this.phone.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, `$1-$2-$3`).replace(/(-{1,2})$/g, '')
        this.joinData.phone = this.phone.replace(/[^0-9]/g, '')
      }else{
        this.joinData.phone = null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#page > .join {
  padding-bottom: 21.4rem;
  height: 100%;
}

.join-inputbox {
  overflow: hidden;
  height: 11.2rem;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  font-size: 2.2rem;
  input {
    line-height: 7rem;
    font-size: inherit;
    color: #000000;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 0 1em;
    width: 100%;
    &::placeholder {
      color: #919191;
    }
  }

  .btn-send-sms {
    float: right;
    margin: 1.5rem 0;
    width: 11rem;
    line-height: 3.6rem;
    border-radius: 1.8rem;
    background-color: #ffffff;
    border: 0.1rem solid #979797;
    font-size: 1.8rem;
    color: #5a5a5a;
  }

  .join-errorbox {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 11rem;
    font-size: inherit;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);

    .error-icon {
      margin: 0 1rem 0 3rem;
      float: left;
    }
  }
}

p {
  margin: 0;
  font-size: 1.8rem;
  color: #a6a6a6;
  a {
    color: inherit;
    text-decoration: underline;
    text-underline-position: under;
  }
}
</style>
