var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container join" },
    [
      _c("div", {
        staticClass: "ui-border-line",
        staticStyle: { height: "10px" }
      }),
      _vm.step === 1 && !this.joinData.isAutoSet
        ? [
            _c("div", { staticClass: "join-inputbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.joinData.userName,
                    expression: "joinData.userName"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "이름(실명)",
                  required: ""
                },
                domProps: { value: _vm.joinData.userName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.joinData, "userName", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "ui-border-line" })
          ]
        : _vm._e(),
      _vm.step >= 2
        ? [
            _c("div", { staticClass: "join-inputbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone"
                  }
                ],
                ref: "phone",
                staticStyle: { width: "30rem" },
                attrs: {
                  type: "text",
                  placeholder: "휴대폰 번호",
                  pattern: "[0-9]+",
                  required: ""
                },
                domProps: { value: _vm.phone },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.phone = $event.target.value
                    },
                    _vm.phoneFormat
                  ]
                }
              }),
              _c(
                "button",
                {
                  staticClass: "btn-send-sms",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.sendSms()
                    }
                  }
                },
                [_vm._v(_vm._s(!_vm.confirm.regNum ? "인증요청" : "재요청"))]
              )
            ]),
            _c("div", { staticClass: "ui-border-line" }),
            _vm.confirm.regNum
              ? _c("div", { staticClass: "join-inputbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.joinData.regNum,
                        expression: "joinData.regNum"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "인증번호 4자리",
                      minlength: "4",
                      maxlength: "6",
                      required: ""
                    },
                    domProps: { value: _vm.joinData.regNum },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.joinData, "regNum", $event.target.value)
                      }
                    }
                  }),
                  _vm.error.regNum
                    ? _c(
                        "div",
                        {
                          staticClass: "join-errorbox",
                          on: {
                            click: function($event) {
                              return _vm.reset("regNum")
                            }
                          }
                        },
                        [
                          _c("icon", {
                            staticClass: "error-icon",
                            attrs: {
                              src: "img/join/register-error-ico.png",
                              width: "2.4rem",
                              height: "11rem",
                              cover: false
                            }
                          }),
                          _vm._v(
                            " 올바른 인증번호가 아닙니다. 다시 시도해주세요. "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _c("div", { staticClass: "ui-border-line" }),
            _c(
              "p",
              { staticClass: "ui-mt-6" },
              [
                _vm._v(" 회원가입을 하면 헤이비글 서비스의 "),
                _c("router-link", { attrs: { to: "/etc/terms" } }, [
                  _vm._v("서비스 약관")
                ]),
                _vm._v(", "),
                _c("router-link", { attrs: { to: "/etc/privacy" } }, [
                  _vm._v("개인정보 보호정책")
                ]),
                _vm._v(", "),
                _c("router-link", { attrs: { to: "/etc/refund-policy" } }, [
                  _vm._v("환불정책")
                ]),
                _vm._v(" 등에 동의하는 것으로 간주됩니다. ")
              ],
              1
            )
          ]
        : _vm._e(),
      _c("footer-box", {
        attrs: {
          "submit-text": _vm.submitText,
          "submit-cb": function() {
            return _vm.nextStep()
          },
          "submit-disabled": _vm.submitDisabled
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }